export default {
    jobs: [
        {
            title: "No positions open currently",
            applicationDeadline: "TBD",
            description: "We have currently filled all of the positions that we have funding for.  Please check back later.",
            requirements: [
                "TBD",
            ]
        },
    ]
}
